<template>
  <div>
    <div class="row">
      <div class="col-12 mb-4">
        <h4 class="blue-text font-weight-bold">
          <font-awesome-icon
            @click="$router.go(-1)"
            class="mr-3"
            :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
          />
          <span>依頼詳細を表示</span>
        </h4>
      </div>
      <div class="col-12">
        <div class="row m-0" v-if="usersData">
          <div class="col-12 col-md-6 col-lg-6 cl-xl-6 mb-5">
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">User ID:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">{{ usersData.id }}</p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">名前 (姓):</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">
                  {{
                    usersData.user_profile
                      ? usersData.user_profile.last_name || 'N/A'
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">名前 (名):</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">
                  {{
                    usersData.user_profile
                      ? usersData.user_profile.first_name || 'N/A'
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">法人名:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">
                  {{
                    usersData.user_profile
                      ? usersData.user_profile.company_name || 'N/A'
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">生年月日:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">
                  {{
                    this.currentUser.user_profile
                      ? usersData.user_profile.birth_date || 'N/A'
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">性別:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">
                  {{
                    this.currentUser.user_profile
                      ? getGenderByText(usersData.user_profile.gender)
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">都道府県:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <div v-if="this.currentUser.user_profile">
                  <p class="ellipsis-1" v-if="prefecture">
                    {{ prefecture }}
                  </p>
                  <b-spinner small v-else></b-spinner>
                </div>
                <div v-else>
                  <p class="ellipsis-1">N/A</p>
                </div>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">アカウントのタイプ:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">
                  {{
                    usersData.user_role ? usersData.user_role.role.name : 'N/A'
                  }}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">メールアドレス:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">{{ usersData.email }}</p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">課金履歴:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <router-link
                  :to="{
                    name: 'adminRoute-users-billing-history',
                    params: {
                      id: usersData.id,
                    },
                  }"
                  class="black-text text-decoration-underline"
                >
                  <p class="ellipsis-1">課金履歴を表示する</p>
                </router-link>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">注文履歴:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <router-link
                  :to="{
                    name: 'adminRoute-users-order-history',
                    params: {
                      id: usersData.id,
                    },
                  }"
                  class="black-text text-decoration-underline"
                >
                  <p class="ellipsis-1">ステージング注文履歴を表示</p>
                </router-link>
              </div>
            </div>

            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">ユーザタグ:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">
                  {{
                    usersData.user_profile
                      ? formatTags(usersData.user_profile.tag_id) || 'N/A'
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>

            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">決済方法:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">
                  {{
                    usersData.user_profile
                      ? getInvoiceByText(usersData.user_profile.is_invoice_payment)
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6 cl-xl-6 mb-5">
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">属性 :</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">N/A</p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">間取りタイプ :</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">N/A</p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">建物の種類 :</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">N/A</p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">管理物件数 :</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">N/A</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0" v-else>
          <div class="col-12 col-md-6 col-lg-6 cl-xl-6 mb-5">
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">User ID:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">名前 (姓):</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">名前 (名):</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">生年月日:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">性別:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">都道府県:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">アカウントのタイプ:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">メールアドレス:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">課金履歴:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">注文履歴:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6 cl-xl-6 mb-5">
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">属性 :</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">間取りタイプ :</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">建物の種類 :</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">管理物件数 :</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Services
import { mapGetters } from 'vuex';
import { globalService_GetAllPrefectures } from '../../services/global';
import { adm_userService_GetDetailsByID } from '../../services/admin/users';
import { getInvoice } from '../../common/Utility';
import { getGender } from '../../common/Utility';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Dashboard',
    };
  },

  data() {
    return {
      search: null,
      usersData: null,
      prefecture: null,
      tags: null,
    };
  },

  mounted() {
    this.setUserData();
    console.log(this.currentUser)
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    getInvoice,
    getGender,
    getGenderByText(gender) {
      var myGender = getGender().filter(function (g) {
        return g.value == gender;
      });
      return myGender[0] ? myGender[0].text : '';
    },
    getInvoiceByText(is_invoice_payment) {
      var invoice = getInvoice().filter(function (g) {
        return g.value == is_invoice_payment;
      });
      return invoice[0] ? invoice[0].text : '';
    },
    setUserData() {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');
      let userID = location.pathname.split('/admin/users/info/')[1];
      console.log(userID);

      adm_userService_GetDetailsByID({ id: userID, token: accessToken })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.usersData = data;
          _this.tags = data.tags;
          console.log(data.tags);

          if (data.user_profile) {
            _this.formatPrefectures(data.user_profile.prefectures);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    formatPrefectures(prefecture) {
      let _this = this;

      globalService_GetAllPrefectures()
        .then((response) => {
          let data = response.data;

          data.forEach((el) => {
            if (el.id === parseInt(prefecture)) {
              _this.prefecture = el.name;
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });

      return 'wew';
    },
    formatTags(tagId) {
      if (tagId == null) {
        return null;
      }
      return this.tags.find(e => e.id === tagId)?.name;
    },
  },
};
</script>

<style scoped>
/* Init Style */
.main-content {
  padding: 110px 60px 150px 350px;
}

@media only screen and (max-width: 991px) {
  .main-content {
    padding: 120px 35px 80px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .main-content {
    padding: 120px 10px 80px 10px;
  }
}

.table thead {
  background: #fff;
}

.table thead th {
  border-bottom: 0;
  color: #000;
  font-weight: bold !important;
  text-align: center;
  border-top: 0;
  font-weight: 400;
}

.table tbody td {
  border: none;
  cursor: pointer;
}

.table tbody tr {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.table tbody tr:hover {
  background: #d1d1d1;
}

/* Custom Style */
#table-top-ation {
  display: flex;
}

@media only screen and (max-width: 767px) {
  #table-top-ation {
    display: block;
  }
}

#table-top-ation .search {
  display: flex;
  margin-right: 20px;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid #d1d1d1;
}

@media only screen and (max-width: 767px) {
  #table-top-ation .search {
    margin-bottom: 14px;
  }
}

#table-top-ation .search .icon {
  font-size: 24px;
  padding: 10px;
  text-align: center;
  background: #fff;
  color: #d1d1d1;
}

#table-top-ation .search input {
  width: 100%;
  border: none;
  padding: 10px;
}

#table-top-ation .search input:focus {
  outline: none;
}

#table-top-ation .add-user {
  width: 200px;
}

@media only screen and (max-width: 767px) {
  #table-top-ation .add-user {
    width: 100%;
  }
}
</style>
